@import 'Fonts.css';



:root {
  --text-super-light: #fcf6f9;
  --text-light: #D1C8CD;
  --text-dark: #707070;
  --text-accent-color: #9068C4;
  --background-dark: #404040;
  --background-super-dark: #1d1d1d;

  --border-margin: 1.4rem;
  --border-margin-top: 2.8rem;

  --gap-normal: 6rem;
  --gap-almostnormal: 4rem;
  --gap-small: 1.6rem;
  --gap-tiny: 0.4rem;
}

* {
  margin: 0;
}

body {
  margin: 0;
}

nav {
  /* background-color: aqua; */

  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  
}

.App {
  font-family: 'Satoshi-Medium';
  font-size: xx-large;
  color: var(--text-dark);

  /* margin: 2rem 2rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  height: 100vh;

  /* hotfix for footer width more than 100% 
  when using vw100 and scrollbar is visible */
  overflow-x: hidden;
}

a{
  color: var(--text-accent-color);
}

h1 {
  font-family: 'PermanentMarker-Regular';
  line-height: 2.6rem;
  text-align: left;
  letter-spacing: -0.23rem;
}

@media (min-width: 768px) {
  h1 {
    font-size:6rem;
    line-height: 3.8rem;
  }
}



h2 {
  font-size: large;
  line-height: 1.4rem;
  /* text-align: center; */
}

h3 {
  font-family: 'Satoshi-Bold';
  font-size: 1.8rem;
  text-transform: uppercase;
  color: var(--text-accent-color);
}

p {
  font-size: x-large;
  line-height: 2.4rem;
}

ul {
  list-style-type: none;
  font-size: medium;
  display: flex;
  flex-direction: column;
  gap:1rem;
  padding: 0rem;
}

li {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  gap: 0.6rem;
}

.max-width {
  max-width: 800px;
}
.container{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.reels {
  display:flex;
  flex-direction: column;
  gap: var(--gap-small)
}

.vimeo-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  background-color: black;
}

.vimeo-title-container{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10;

  display:flex;
  align-items: center;
  justify-content: center;

  font-family: 'Satoshi-Black';
  text-transform: uppercase;
  color:var(--text-super-light);

  pointer-events: none;
}

.vimeo-container-thumbnail {
  filter:brightness(0.75);

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index:5;

}

.h1-small{
  font-size: 2.8rem;
  letter-spacing: 0.01rem;
  line-height: 2rem;
  margin-bottom:0;
}

footer {
  display: block;
  margin-top: auto; /* moves always to bot*/
  gap: var(--gap-tiny);
  width: 100vw;

  padding-top: 4rem;
  padding-bottom: 12rem;

  background-color: var(--background-dark);
  color: var(--text-light);
}

 
.contact-details-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .contact-details-container {
    flex-direction: column;
    align-items: center;
  }
}

.contact-details-item {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  justify-content: center;
  
}

.contact-details-item.flexbox-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-evenly;
}

.contact-details-link-icon {
  color: var(--text-accent-color);
  
}

.contact-details-link {
  text-decoration: none;
  display: flex;
}

.contact-details-link:hover {
  /* padding-bottom:0.2rem; */
  border-bottom: solid 0.4rem var(--text-accent-color);
}

.avatar-container {
  overflow:hidden;
  height: 15rem;
  width: 15rem;
  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 10px;
  margin-left:auto;
}

@media (max-width: 768px) {
  .avatar-container {
    margin-left:0;

    height: 10rem;
    width: 10rem;

    
  }
}

.avatar {
  width: 100%;
  height: auto;
}






.highlight-text{
  font-family: 'Satoshi-Bold';
  color: var(--text-accent-color)
}




.margin-border{
  margin: 0rem var(--border-margin);
}

.margin-border-top{
  margin-top: var(--border-margin-top)
}

.gap-top-large {
  margin-top: var(--gap-large)
}

.gap-top-normal {
  margin-top: var(--gap-normal)
}
.gap-top-almostnormal {
  margin-top: var(--gap-almostnormal)
}

.gap-top-small {
  margin-top: var(--gap-small)
}
.gap-top-tiny {
  margin-top: 0.8rem;
}

.icon {
  margin-left: var(--gap-tiny);
}

.dove {
  height: 3rem;
  position: absolute;
  transform: translateY(0.4rem)
}


.info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem 0rem;
}

@media (max-width: 768px) {
  .info-container {
    flex-direction: column; /* Switch to one-column layout */
  }
}

.info-item {
  flex: 1;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  box-sizing: border-box;
}


/* Table */

.tableContainer {
  display: flex;
  flex-direction: column;
}


.table-cell {
  height: var(--gap-almostnormal);
  border-top: 0.15rem solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Satoshi-Regular';
  font-size: large;

  
}

.tagline {
  font-family: 'Satoshi-Regular';
  font-size: large;
  color: var(--text-light);

  font-family: 'Satoshi-Regular';
  font-size: small;
  color: var(--text-light);
  line-height: 1.2rem;
}

.table-link {
  color: var(--text-dark);
  text-decoration: none;
  
}

.table-link:hover {
  border-bottom: solid 0.4rem var(--text-accent-color);
}



/* ANIMATION */

.fade-in-section {
  opacity: 0;
  transform: translateY(2vh);
  visibility: hidden;
  transition: opacity 1000ms ease-out, transform 800ms ease-out,
    visibility 1000ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}





/* videoplayer */


.player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.player-wrapper:hover{
  cursor: pointer;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.play-pause-button,
.fullscreen-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0,0);
  color: #fff;
  border: none;
  /* padding: 10px; */
  border-radius: 50%;
  z-index: 10;
}


.password-disclaimer{
  position: absolute;
  display:none;
  bottom: 0;
  left: 0;
  font-family: 'Satoshi-Regular';
  font-size: small;
  color: var(--text-light);
  line-height: 1.2rem;
  z-index: 50;

  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
}

.credits {
  font-family: 'Satoshi-Regular';
  font-size: small;
  background: var(--background-super-dark);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem 0rem;
}


.fade-element {
  transition: opacity 0.7s ease; /* Set the transition property for opacity */
}

.fade-out {
  opacity: 0; /* Set opacity to 0 for the fade-out effect */
  pointer-events: none;
}