/* File: font.css */

@font-face {
    font-family: 'Satoshi-Black';
    src: local('Satoshi-Black'),
         url('./fonts/Satoshi-Black.woff') format('woff')
}

@font-face {
    font-family: 'Satoshi-Bold';
    src: local('Satoshi-Bold'),
         url('./fonts/Satoshi-Bold.woff') format('woff')
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: local('Satoshi-Medium'),
         url('./fonts/Satoshi-Medium.woff') format('woff')
}
@font-face {
    font-family: 'Satoshi-Regular';
    src: local('Satoshi-Regular'),
         url('./fonts/Satoshi-Regular.woff') format('woff')
}

@font-face {
    font-family: 'PermanentMarker-Regular';
    src: local('PermanentMarker-Regular'),
         url('./fonts/PermanentMarker-Regular.woff') format('woff')
}